$highlight-background   : #1d1f21;
$highlight-foreground   : #c5c8c6;
$highlight-comment      : #969896;
$highlight-red          : #c66;
$highlight-orange       : #de935f;
$highlight-yellow       : #f0c674;
$highlight-green        : #b5bd68;
$highlight-aqua         : #8abeb7;
$highlight-blue         : #81a2be;
$highlight-purple       : #b294bb;
